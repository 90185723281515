<template>
	<div class="container flex f-column f-align">
		<span class="font40 black bold">开门提示</span>
		<img style="width: 21.75rem;height: 19.0625rem;" src="../assets/image/kaimentu@2x.png" />
		<span class="font40 black bold">{{ xmbh }}</span>
		<span class="font30 gray">箱门已打开，{{word}}</span>

		<div class="flex">
			<div class="btn" @click="goOn" v-if="type == 'user-cun' || type == 'xy-cun' || type == 'user-qu'">
				返回首页
			</div>
			<div class="btn" @click="$router.push('./orderList')" v-if="type == 'xy-qu'">
				绑定衣扣
			</div>
			<div class="btn" @click="$router.push('./shoesCode')" v-if="type == 'xy-cun'">
				配送完成
			</div>
		</div>
		<span class="open-text" @click="show = true">箱门未打开?</span>

		<van-dialog v-model="show" width="1000px" :showConfirmButton="false">
			<div class="flex f-column f-align dialog-top">
				<div class="header">箱门没打开?</div>
				<div class="flex f-column">
					<div class="font36" style="line-height: 3.125rem">
						<span>1.打开箱门时能听到开门锁响声，但是门没有开，有可能是门被卡住了，可尝试按压一下箱门，然后再次进行开门操作！</span>
						<span style="color: #325CC7;text-decoration: underline;" @click="openAgain()">打开箱门</span>
					</div>
					<div class="font36" style="line-height: 3.125rem;margin-top: 3.75rem;">
						<span>2.如无法打开请联系管理员</span>
						<span style="color: #325CC7;">联系电话：{{phone}}</span>
					</div>
				</div>
			</div>
			<div class="dialog-bottom" @click="show = false">返　回</div>
		</van-dialog>
	</div>
</template>
<script>
	import axios from 'axios'
	import qs from 'querystring'

	export default {
		data() {
			return {
				show: false,
				phone: localStorage.getItem('phone'),
				xmbh: "",
				word: "",
				type: '',



				mark: 0,
				xms: [],
				langnum: '',
				smallnum: '',
			};
		},
		mounted() {
			this.type = localStorage.getItem("type");
			if (this.type == "user-cun") {
				this.$emit('change', '')
				this.word = "衣物存入后请关闭箱门";
				this.xmbh = localStorage.getItem("xmbh");
			} else if (this.type == "xy-qu") {
				this.$emit('change', '/quClothes')
				this.word = "衣物取出后请关闭箱门";
				this.xmbh = localStorage.getItem("xmbh");
			} else if (this.type == "xy-cun") {
				this.$emit('change', '/adminList')
				this.word = "衣物存入后请关闭箱门";
				this.xmbh = localStorage.getItem("xmbh");
			} else if (this.type == "user-qu") {
				this.$emit('change', '')
				this.word = "衣物取出后请关闭箱门";
				this.xmbh = localStorage.getItem("xmbh");
			}
			// this.getDataList();
		},
		methods: {
			getDataList() {
				this.$post('/box/getBoxCount', {
					gzbh: localStorage.getItem('gzbh')
				}).then((res) => {
					if (res.code == 0) {
						let data = res.data
						for (let i = 0; i < data.length; i++) {
							if (data[i].type == '长') {
								this.langnum = data[i].num
							} else {
								this.smallnum = data[i].num
							}
						}
					}
				})
			},
			goOn() {
				this.$router.replace("/");
			},
			openAgain() {
				let openCmd = localStorage.getItem('openCmd')
				let portName = localStorage.getItem('portName')
				if (openCmd && portName) {
					AndroidInterFace.open(portName, openCmd)
				}
			}
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding: 50px 0 30px;
		justify-content: space-between;

		.btn {
			width: 250px;
			height: 80px;
			background: rgba(64, 130, 253, 1);
			border-radius: 6px;
			font-size: 30px;
			line-height: 80px;
			color: #FFFFFF;
			text-align: center;
			margin: 0 90px;
		}

		.open-text {
			font-size: 30px;
			line-height: 42px;
			color: #AAAAAA;
			text-decoration: underline;
		}
	}

	::v-deep .van-dialog {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 0;

		.van-dialog__content {
			border-radius: 50px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.dialog-top {
				background-color: #ffffff;
				border-radius: 50px;
				padding: 0 55px 90px;
			}

			.header {
				font-size: 50px;
				line-height: 70px !important;
				padding-top: 60px;
				padding-bottom: 30px;
				position: relative;
				color: #000000;
				background-color: #ffffff;
				border-radius: 50px 50px 0 0;
			}

			.dialog-bottom {
				width: 250px;
				height: 80px;
				background: #FFFFFF;
				border-radius: 6px;
				font-size: 36px;
				font-weight: 800;
				color: #333333;
				line-height: 80px;
				text-align: center;
				margin-top: 75px;
			}
		}
	}
</style>
